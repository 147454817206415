<template>
  <div class="svg-line" @click.stop="deactivated" :style="{ width: width+20 + 'px', height: height+20 + 'px', position: 'absolute',zIndex:selectLine?101:100, top: y-10 + 'px', left: x-10 + 'px',borderRadius:'4px' }">
    <a-icon @click.stop="activated" :style="getArrowStyle(startX+10, endX+10, endY+10, startY+10, width, height)" type="caret-right" />
    <svg @click.stop="deactivated" :style="{ width: width+20 + 'px', height: height+20 + 'px',position:'relative',}">
      <line
        @click.stop="activated"
        @mousedown.stop="lineMouseDwon"
        :x1="getPositions().startX+10"
        :y1="getPositions().startY+10"
        :x2="getPositions().endX+10"
        :y2="getPositions().endY+10"
        stroke="red"
        stroke-width="4"
      />
    </svg>
    <div v-if="selectLine" @click.stop @mousedown.stop="lineStartMousedown" class='line-handle start-line-handle' :style="{left:getPositions().startX+5+'px',top:getPositions().startY+5+'px'}"></div>
    <div v-if="selectLine" @click.stop @mousedown.stop="lineEndMousedown" class='line-handle end-line-handle' :style="{left:getPositions().endX+5+'px',top:getPositions().endY+5+'px'}"></div>
  </div>
</template>
<script>
export default {
  name: 'SvgLine',
  props: {
    startX: {
      type: Number,
      default: 0
    },
    startY: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    endX: {
      type: Number,
      default: 0
    },
    endY: {
      type: Number,
      default: 0
    },
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    }
  },
  data(){
    return {
      selectLine:null
    }
  },
  methods: {
    lineMouseDwon(event){
      this.$emit('lineMouseDwon',event)
    },
    lineStartMousedown(event){
      this.$emit('lineStartMousedown',event)
    },
    lineEndMousedown(event){
      this.$emit('lineEndMousedown',event)
    },
    activated(){
      if(!this.selectLine){
        this.$emit('activated')
      }
    },
    deactivated(){
      if(this.selectLine){
        this.$emit('deactivated')
      }
    },
    calculateAngle(x1, y1, x2, y2) {
      // 计算两点之间的相对位置
      const deltaX = x2 - x1
      const deltaY = y2 - y1

      // 使用 Math.atan2 计算角度（弧度）
      const angleInRadians = Math.atan2(deltaY, deltaX)

      // 将弧度转换为角度
      const angleInDegrees = angleInRadians * (180 / Math.PI)

      return angleInDegrees
    },
    getArrowStyle(start_x, end_x, end_y, start_y, mask_line_width, mask_line_height) {
      let style = 'position: absolute;color:red;font-size:20px;'
      if (start_x > end_x && end_y > start_y) {
        style =
          style +
          `transform: rotate(${this.calculateAngle(0, 0, 0 - mask_line_width, mask_line_height).toFixed(2)}deg);`
        style = style + 'left:-0;bottom:-0;'
      } else if (start_x > end_x && start_y > end_y) {
        style =
          style +
          `transform: rotate(${(this.calculateAngle(0, 0, mask_line_width, mask_line_height) + 180).toFixed(2)}deg);`
        style = style + 'left:-0;top:-0;'
      } else if (end_x > start_x && start_y > end_y) {
        style =
          style +
          `transform: rotate(${this.calculateAngle(0, 0, mask_line_width, 0 - mask_line_height).toFixed(2)}deg);`
        style = style + 'right:-0;top:-0;'
      } else if (end_x > start_x && end_y > start_y) {
        style =
          style + `transform: rotate(${this.calculateAngle(0, 0, mask_line_width, mask_line_height).toFixed(2)}deg);`
        style = style + 'right:-0;bottom:-0;'
      } else if (end_x == start_x && start_y > end_y) {
        style =
          style +
          `transform: rotate(${this.calculateAngle(0, 0, mask_line_width, 0 - mask_line_height).toFixed(2)}deg);`
        style = style + 'right:-0;top:-0;'
      } else if (start_x > end_x && start_y == end_y) {
        style =
          style +
          `transform: rotate(${(this.calculateAngle(0, 0, mask_line_width, mask_line_height) + 180).toFixed(2)}deg);`
        style = style + 'left:-0;top:-0;'
      } else {
        style =
          style + `transform: rotate(${this.calculateAngle(0, 0, mask_line_width, mask_line_height).toFixed(2)}deg);`
        style = style + 'right:-0;bottom:-0;'
      }
      return style
    },
    getPositions() {
      let startX,
        startY,
        endX,
        endY = 0
      if (this.startX > this.endX && this.endY > this.startY) {
        startX = this.width
        startY = 0
        endX = 0
        endY = this.height
      } else if (this.startX >= this.endX && this.startY >= this.endY) {
        startX = this.width
        startY = this.height
        endX = 0
        endY = 0
      } else if (this.endX > this.startX && this.startY > this.endY) {
        startX = 0
        startY = this.height
        endX = this.width
        endY = 0
      } else if (this.endX >= this.startX && this.endY >= this.startY) {
        startX = 0
        startY = 0
        endX = this.width
        endY = this.height
      }
      return {
        startX,
        startY,
        endX,
        endY
      }
    }
  }
}
</script>
<style scoped lang="less">
</style>
